// NAVBAR TOP
.navbar {
  padding: 16px 40px;
  transition: ease-in-out 300ms all;
  position: relative;
}

.nav-transparent .navbar {
  background: transparent !important;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.navbar-brand {
  margin-right: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.navbar-brand {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 170px;
    height: auto;
  }
}

.nav-dark .navbar-brand.white {
  display: none;
}

.nav-transparent .navbar-brand.dark {
  display: none;
}

.navbar .left-navigation-action {
  background: transparent;
  border: none;
  font-family: $headings-font-family;
  letter-spacing: 5.45px;
  color: #737372;
  font-size: 16px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 5px;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.is-fixed .left-navigation-action {
  @include media-breakpoint-down(sm) {
    margin-top: 8px;
  }
}

.navbar-toggler-icon {
  position: relative;
  width: 30px;
  height: 18px;
  z-index: 99;
  cursor: pointer;
  margin-right: 10px;
  background: none !important;
  background-image: none !important;
  display: block;

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 5px;
  }

  span {
    display: block;
    height: 1px;
    width: 100%;
    background: #737372;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    position: relative;

    &:first-child {
      top: 0;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 6px;
    }

    &:nth-child(3) {
      top: 12px;
      transform-origin: left center;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.nav-dark.is-fixed .navbar-toggler-text,
.nav-transparent .navbar-toggler-text {
  color: white;
}

.nav-dark.is-fixed .navbar-toggler-icon,
.nav-transparent .navbar-toggler-icon {
  span {
    background: white;
  }
}

.navbar-toggler-text {
  line-height: 1;
}

.navbar-right {
  margin-top: 10px;
}

.user-dropdown {
  margin-right: 20px;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
    margin-left: 14px;
  }

}

.main-navigation {
  .navbar {
    .nav-link {
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
    }
  }
}

.lang-switcher .nav-link,
.user-dropdown .nav-link {
  font-family: $headings-font-family;
  font-size: 18px;
  line-height: 2.47;
  letter-spacing: 2px;
  color: #f2f2f2;
  padding: 0 !important;
  text-transform: uppercase;
}


.nav-transparent .lang-switcher .nav-link,
.nav-transparent .user-dropdown .nav-link,
{
  color: $white;

  &:hover, &:focus {
    color: $white;
  }
}

.navbar-light .navbar-nav .nav-link
.lang-switcher .dropdown-toggle::after {
  display: inline-block;
  margin-left: 2px;
  vertical-align: 0.255em;
  content: "";
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-left: 4px solid transparent;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.nav-search .dropdown-toggle::after {
  display: none;
}

.nav-search {
  .nav-link {
    transform: translateY(-3px);
    margin-right: 0;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }

  .dropdown-menu {
    min-width: 300px;
    margin-top: 25px !important;


    .search-property-form {
      position: relative;

      &:before {
        content: "\A";
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #ccc;
        position: absolute;
        right: 10px;
        top: -12px;
      }

      .form-group {
        margin: 0;

        .form-control {
          border: none;
        }
      }
    }
  }
}


.has--languages .nav-search .nav-link {
  @include media-breakpoint-up(md) {
    margin-right: 15px;
  }
}

.navbar.has--languages .line2 {
  width: calc(50% - 190px);
}

.nav-dark .nav-search .nav-link,
.nav-dark .lang-switcher .nav-link {
  color: #737372;
}

.nav-search .nav-link .cli {
  font-size: 18px;
  font-weight: 600;
}

.nav-transparent .nav-search .nav-link .cli {
  color: $white;
}

.navbar .line1 {
  width: calc(50% - 610px);
  height: 1px;
  background: #f2f2f2;
  position: absolute;
  top: 50%;
  left: 530px;

  @include media-breakpoint-down(lg) {
    width: calc(50% - 313px);
    left: 227px;
  }
}

.navbar .line2 {
  width: calc(50% - 182px);
  height: 1px;
  background: #f2f2f2;
  position: absolute;
  top: 50%;
  left: calc(50% + 83px);
}

.navbar.has-languages .line2 {
  width: calc(50% - 252px);
}

.navbar.has--logged-in-user .line2 {
  width: calc(50% - 376px);
}

.navbar.has--logged-in-user.has-languages .line2 {
  width: calc(50% - 425px);
}


.nav-dark .navbar .line1,
.nav-dark .navbar .line2 {
  background: #737372;
}

.is-fixed .navbar {
  height: 55px;
  padding-top: 0;
  padding-bottom: 0;
  @include grainOverlay();
}

.is-fixed .navbar-brand {
  align-items: flex-start;

  img {
    width: 170px;
    transform: translateY(-25px);

    @include media-breakpoint-down(sm) {
      transform: translateY(-29px);
    }
  }
}

.is-fixed .navbar-light .navbar-brand {
  //overflow: hidden;
  height: 32px;
}

.is-fixed .navbar-right {
  margin-top: 3px;
}

.is-fixed .navbar .line1,
.is-fixed .navbar .line2 {
  top: 27px;
}

.nav-dark.is-fixed .nav-search .nav-link,
.nav-dark.is-fixed .lang-switcher .nav-link,
.nav-dark.is-fixed .user-dropdown .nav-link,
.nav-dark.is-fixed .navbar .left-navigation-action {
  color: $white;
}

.nav-dark.is-fixed .navbar-light .navbar-brand {
  filter: brightness(5) grayscale(1);
}

.nav-dark.is-fixed .navbar .line1,
.nav-dark.is-fixed .navbar .line2 {
  background: $white;
}

.b-nav-dropdown.user {
  display: none;
}

.secret-sales .b-nav-dropdown.user {
  display: list-item;
}

.secret-sales .b-nav-dropdown.user .nav-link span {
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.secret-sales .b-nav-dropdown.user .dropdown-toggle::after {
  transform: translateY(-5px);
}

.secret-sales .navbar-brand {
  margin-right: -150px;
}

.secret-sales .b-nav-dropdown.user .nav-link {
  color: $white;
}

.secret-sales .navbar .line2 {
  width: calc(50% - 400px);
  right: 310px;
}

@include media-breakpoint-down(sm) {
  .navbar {
    padding: 8px 13px;
  }
  .navbar .line1,
  .navbar .line2 {
    display: none;
  }
  .navbar-right {
    margin-top: 5px;
  }
  .navbar-brand {
    margin-right: -40px;
  }
  .navbar-brand img {
    //width: 85px;
    margin-top: 4px;
  }
  .navbar-toggler-text {
    font-size: 10px;
    letter-spacing: 2.5px;
    transform: translateY(-21px);
  }
  .lang-switcher .nav-link {
    font-size: 20px;
  }
  .lang-switcher .dropdown-toggle::after {
    margin-left: 2px;
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-bottom: 0;
    border-left: 0.2em solid transparent;
  }
}


// SIDE MENU
#modal-menu .nav-side {
  height: 100%;
  padding: 30px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;


  @include media-breakpoint-down(sm) {
    padding: 0;
    align-content: flex-start;
  }
}

#modal-menu .modal-content {
  z-index: 30;
  border: none;
  border-radius: 0;
  padding: 0;
  background: white;
  height: 100vh;
  box-shadow: 0 1px 6px 0 #00000033, 0 3px 1px -2px #0000001e, 0 2px 2px 0 #00000023;
}

#modal-menu .nav-side .navbar-nav {
  flex-direction: column;

  &:after {
    content: "";
    width: 190px;
    height: 0.5px;
    background: #494948;
    margin: 30px 0;

    @include media-breakpoint-down(sm) {
      margin: 15px 0;
      width: 205px;
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

#modal-menu .nav-side .navbar-nav .nav-item {
  display: block;
  padding: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 4px 0;
  }

  @media (max-height: 850px) {
    padding: 4px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 6px 0;
  }

  @media (max-height: 780px) {
    padding: 6px 0;
  }
}

#modal-menu .navbar-wrapper {
  padding: 0 70px;
  flex-grow: 1;

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    flex-grow: 0;
  }
}

#modal-menu .nav-side .navbar-nav .nav-link {
  font-family: $headings-font-family;
  font-size: 16px;
  letter-spacing: 3.4px;
  color: #494948;
  text-transform: uppercase;
  font-weight: 300;

  &:hover, &:focus {
    color: $navi;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 1.4;
  }

  @media (max-height: 780px) {
    font-size: 14px;
    line-height: 1.4;
  }
}

#modal-menu .modal-footer {
  display: none;
}


#modal-menu .modal-header {
  padding: 0;
  border: none;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 50px;
  position: relative;
}

#modal-menu .modal-body {
  padding: 0;
}

#modal-menu .close {
  font-family: $icons-font-family;
  text-indent: 0;
  position: relative;
  height: 14px;
  width: 14px;
  padding: 0;
  margin: 0;
  color: transparent;
  opacity: 1;
  font-size: 14px;

  &:active,
  &:focus {
    outline: none !important;
    border: none !important;
  }

  &:before {
    content: '\e928';
    position: absolute;
    top: 0;
    left: 0;
    color: #949494;
  }
}


#modal-menu .modal-title {
  width: calc(100% - 40px);
  margin-bottom: 0;
  line-height: 1.5;
  border-bottom: solid 1px #bbbbbb;
  transform: translateY(7px);
}

#modal-menu .socials {
  font-family: $headings-font-family;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 2.8px;
  color: #494948;
  text-transform: uppercase;
  width: 100%;
  padding: 40px 70px;
  border-top: 0.5px solid opacify($dark-grey, 0.8);

  @include media-breakpoint-down(sm) {
    bottom: 85px;
    border: none;
  }

  @media (max-height: 780px) {
    bottom: 85px;
  }

  .socials-title {
    display: inline-block;
    margin-bottom: 18px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @media (max-height: 780px) {
      display: none;
    }
  }

  .icons {
    display: flex;
    gap: 20px;
  }

  .cli {
    color: #2c2b2f;
    font-size: 26px;
    opacity: 0.5;

    @include media-breakpoint-down(sm) {
      font-size: 34px;
      line-height: 40px;

      &.cli-linkedin {
        font-size: 36px;


        &:before {
          margin-top: -7px;
        }
      }

      &.cli-youtube {
        font-size: 44px;
      }
    }
  }
}

#modal-menu .socials .cli:hover {
  color: #1f3d45;
  opacity: 1;
}

#modal-menu .secret-login-button-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#modal-menu .secret-login-button {
  position: relative;
  font-family: $headings-font-family;
  font-size: 16px;
  letter-spacing: 3.5px;
  color: #494948;
  text-align: center;
  text-transform: uppercase;
  border: none;
  width: 100%;
  height: 65px;
  border-top: 1px solid #494948;

  .cli {
    font-size: 19px;
    transform: translate(-13px, 0px);
    font-weight: 600;
    color: #1f3d45;
  }
}


#modal-menu .modal-header {
  margin-bottom: 10px;

  .close {
    font-family: $icons-font-family;
    font-size: 26px;
    transform: rotate(-90deg) translate(-6px, -14px);

    &:before{
      content: '\E92F';
    }
  }
}


#modal-menu .nav-side .navbar-nav {

  .nav-link {
    padding: 4px 0;
  }

  .nav-item {
    &.active {
      .nav-link {
        font-weight: bold;
      }
    }
  }

}


// MODAL SLIDE STYLE
#modal-menu .modal-dialog {
  top: 0;
  left: 0;
  position: fixed;
  margin: auto;
  width: 450px;
  height: 100%;
  transform: translate3d(0%, 0, 0);

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

#modal-menu .modal-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}

#modal-menu.fade .modal-dialog {
  left: -350px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

#modal-menu.fade.show .modal-dialog {
  left: 0;
}

.main-navigation {
  .navbar-brand {
    transition: all 300ms ease-in-out;
  }

  @include media-breakpoint-down(sm) {
    &.is--search-open {
      .navbar-brand {
        opacity: 0;
      }
    }
  }
}
